(function () {
  angular.module('kmi.components', []).directive('modal', [
    '$uibModal',
    function ($uibModal) {
      return {
        restrict: 'A',
        scope: true,
        terminal: true,
        link: function postLink(scope, element, attrs) {
          if (attrs.modal) {
            scope.window = null;
            scope.template = element.html();
            element.remove();
            scope.$watch(attrs.modal, function (value) {
              if (value) {
                scope.window = $uibModal.open(
                  angular.extend(
                    {
                      template: scope.template,
                      scope: scope.$new(),
                      windowClass: 'in',
                    },
                    scope.$eval(attrs.options),
                  ),
                );
              } else if (scope.window) {
                scope.window.close();
                scope.window = null;
              }
            });
          }
        },
      };
    },
  ]);
})();

import { Injectable } from '@angular/core';
import { NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the time is represented in scripts i.e. ngModel.
 * https://ng-bootstrap.github.io/#/components/timepicker/examples#adapter
 */
@Injectable()
export class TimepickerAdapter extends NgbTimeAdapter<string> {
  readonly DELIMITER = ':';

  /* @value: YYYY-MM-DD HH:mm */
  fromModel(value: string | null): NgbTimeStruct | null {
    if (value) {
      const time = value.split(/[\sTt]/)[1];
      const [hour, minute, second] = (time || value).split(this.DELIMITER);

      return {
        hour: parseInt(hour, 10),
        minute: parseInt(minute, 10),
        second: second ? parseInt(second, 10) : 0,
      };
    }

    return null;
  }

  /* format: HH:MM */
  toModel(time: NgbTimeStruct | null): string | null {
    return time && time.hour >= 0 && time.minute >= 0
      ? this.pad(time.hour) + this.DELIMITER + this.pad(time.minute)
      : null;
  }

  private pad(value: number): string {
    return String(value).padStart(2, '0');
  }
}

angular
  .module('kmi.lms.components', ['kmi.components', 'ui.bootstrap.modal', 'kmi.lms.core', 'globalDependencies'])
  .constant('customAttributesConstants', {
    formPrefix: 'customAttributeForm',
    fieldPrefix: 'customAttribute',
  })
  .constant('patternsLibrary', {
    requiredListCommasSeparated: {
      condition: /^[^,]+(,[^,]+)*$/,
      message: 'Need to text separated by commas',
    },
  })
  .config(function ($sanitizeProvider) {
    $sanitizeProvider.addValidAttrs(['style']);
    $sanitizeProvider.enableSvg(true);
  });

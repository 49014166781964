(function () {
  angular.module('kmi.lms.components').filter('unsafePath', [
    '$sce',
    function ($sce) {
      return function (val) {
        return $sce.trustAsResourceUrl(val);
      };
    },
  ]);
})();

angular.module('kmi.lms.components').factory('dateTimeUtils', dateTime);

function dateTime(moment) {
  return {
    format(format) {
      let merged = merge(this);
      return merged ? merged.format(format || 'YYYY-MM-DD HH:mm') : null;
    },
    newDateTime(date, endDay = false) {
      let dt = Object.create(this);
      dt.date = getDate(date);
      dt.time = getTime(date, endDay ? new Date().setHours(23, 59) : new Date().setHours(0, 0));
      return dt;
    },
    _onChange() {
      let merged = merge(this);
      if (merged) {
        this._date = merged.toDate();
      }
    },
    get date() {
      return this._date;
    },
    set date(date) {
      this._date = date;
      this._onChange();
    },
    set time(tm) {
      this._time = tm;
      this._onChange();
    },
    get time() {
      return this._time;
    },
  };

  function merge(dt) {
    if (!dt.date) {
      return null;
    }
    let mtime = moment(dt.time);
    return moment(dt.date).hours(mtime.hours()).minutes(mtime.minutes());
  }

  function getDate(model) {
    if (model instanceof Date) {
      return model;
    }
    return model ? moment(model.split('T')[0]).toDate() : '';
  }

  function getTime(model, defaultTime) {
    if (model instanceof Date) {
      return model;
    }

    return model && model.contains('T') ? moment(model.split('T')[1], 'HH:mm').toDate() : defaultTime;
  }
}

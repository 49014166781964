(function () {
  window.addEventListener('DOMContentLoaded', load, true);

  function load() {
    var childNodes = document.body.childNodes;
    var clickHandler = function () {};

    for (var i = 0; i < childNodes.length; i++) {
      if (childNodes[i].tagName) {
        childNodes[i].addEventListener('click', clickHandler, true);
      }
    }
  }
})();

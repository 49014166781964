(function () {
  angular.module('kmi.lms.components').component('toggleButton', {
    template: require('ajs/components/forms/toggle-button.html').default,
    controller: ToggleButtonController,
    controllerAs: 'vm',
    transclude: true,
    bindings: {
      value: '=',
      onValueChange: '&?',
      labelRight: '=?',
      mode: '@?',
    },
  });

  /* @ngInject */
  function ToggleButtonController($timeout) {
    var vm = this;
    vm.toggleValue = toggleValue;

    function toggleValue() {
      vm.value = !vm.value;

      if (angular.isFunction(vm.onValueChange)) {
        $timeout(vm.onValueChange);
      }
    }
  }
})();

import * as _ from 'lodash';
// Make sure Lodash is installed

export class AttributesService {
  mergeWithValues(attributes: any[], attributesValues: any[], searchProperty = 'typeId'): void {
    _.forEach(attributesValues, (item) => {
      const foundItem = _.find(attributes, _.matchesProperty(searchProperty, item[searchProperty]));

      if (foundItem) {
        Object.assign(foundItem, _.pick(item, ['specify', 'value', 'valueId']));
      }
    });
  }

  getLookupValueName(attribute): string {
    if (!attribute.lookup) {
      return null;
    }

    const selectedLookupValue = _.find(attribute.lookupValues, (value) => {
      return value.id === attribute.valueId;
    });

    return selectedLookupValue.value;
  }
}

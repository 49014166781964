import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import _ from 'lodash';
import { Unsubscribable } from 'rxjs';

interface IOptionValue {
  key: string;
  value: any;
}

@Component({
  standalone: false,
  selector: 'select-dropdown',
  templateUrl: './select-dropdown.component.html',
})
export class SelectDropdownComponent implements OnInit {
  @Input() options: any[];
  @Input() selectedText: string;
  @Output() handleChange = new EventEmitter();
  @Output() modelChange = new EventEmitter<any>();

  selectedValue: string;
  values: IOptionValue[] = [];
  subscriber: Unsubscribable;

  private _ngModel: any;

  @Input() set model(val: any) {
    this._ngModel = val;
    this.selectedValue = _.get(
      _.find(this.values, (item) => item.value === this._ngModel),
      'key',
      null,
    );
    this.handleChange.emit();
    this.modelChange.emit(val);
  }

  get model(): any {
    return this._ngModel;
  }

  ngOnInit(): void {
    if (this.options) {
      this.values = this.options.map((item) => {
        return { key: item.toString(), value: item };
      });
    }

    this.values.unshift({ key: this.selectedText, value: null });
    this.selectedValue = _.get(
      _.find(this.values, (item) => item.value === this.model),
      'key',
      null,
    );
  }

  selectOption(option: IOptionValue): void {
    this.model = option.value;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerConfig,
  NgbDatepickerModule,
  NgbTimeAdapter,
  NgbTimepickerConfig,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { DateWithWeekdayComponent } from 'components/dates/date-with-weekday.component';
import { DateTimeDirective } from './date-time.component.upgraded';
import { DateWithHiddenTimeDirective } from './date-with-hidden-time.component';
import { DatepickerParserFormatter } from './datepicker-parser.formatter';
import { DatepickerAdapter } from './datepicker.adapter';
import { DatepickerComponent } from './datepicker.component';
import { DatepickerConfig } from './datepicker.config';
import { TimeIndicatorDirective } from './time-indicator.component.upgraded';
import { TimepickerAdapter } from './timepicker.adapter';
import { TimepickerConfig } from './timepicker.config';

@NgModule({
  imports: [CommonModule, NgbDatepickerModule, NgbTimepickerModule],
  declarations: [
    DateTimeDirective,
    DateWithHiddenTimeDirective,
    DatepickerComponent,
    DateWithWeekdayComponent,
    TimeIndicatorDirective,
  ],
  exports: [
    DateTimeDirective,
    DateWithHiddenTimeDirective,
    DatepickerComponent,
    NgbDatepickerModule,
    NgbTimepickerModule,
    DateWithWeekdayComponent,
    TimeIndicatorDirective,
  ],
  providers: [
    { provide: NgbTimepickerConfig, useClass: TimepickerConfig },
    { provide: NgbTimeAdapter, useClass: TimepickerAdapter },
    { provide: NgbDatepickerConfig, useClass: DatepickerConfig },
    { provide: NgbDateAdapter, useClass: DatepickerAdapter },
    { provide: NgbDateParserFormatter, useClass: DatepickerParserFormatter },
  ],
})
export class LmsDatesModule {}

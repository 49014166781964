import { Injectable } from '@angular/core';

export type LaddaStyle =
  | 'expand-left'
  | 'expand-right'
  | 'expand-up'
  | 'expand-down'
  | 'contract'
  | 'contract-overlay'
  | 'zoom-in'
  | 'zoom-out'
  | 'slide-left'
  | 'slide-right'
  | 'slide-up'
  | 'slide-down';

export abstract class LaddaConfigArgs {
  style?: LaddaStyle;
  spinnerSize?: number;
  spinnerColor?: string;
  spinnerLines?: number;
}

@Injectable()
export class LaddaConfig extends LaddaConfigArgs {
  constructor(config: LaddaConfigArgs = {}) {
    super();

    this.style = config.style;
    this.spinnerSize = config.spinnerSize;
    this.spinnerColor = config.spinnerColor;
    this.spinnerLines = config.spinnerLines;
  }
}

import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  standalone: false,
  selector: 'date-with-hidden-time',
})
export class DateWithHiddenTimeDirective extends UpgradeComponent {
  static readonly selector = 'dateWithHiddenTime';

  @Input() date!: string | Date;
  @Input() timeZone?: string;

  constructor(elementRef: ElementRef, injector: Injector) {
    super(DateWithHiddenTimeDirective.selector, elementRef, injector);
  }
}

(function () {
  angular.module('kmi.lms.components').filter('markdownToHtml', markdownToHtml);

  /* @ngInject */
  function markdownToHtml(markdownRender) {
    return function (val) {
      if (!val) {
        return val;
      }

      if (typeof markdownRender === 'function') {
        return '<div class="md-view">' + markdownRender(val) + '</div>';
      }

      return val;
    };
  }
})();

(function () {
  angular.module('kmi.lms.components').directive('editBox', editBox);

  function editBox(backUrlService) {
    var directive = {
      template: require('ajs/components/edit_box/edit-box.html').default,
      transclude: true,
      restrict: 'EA',
      scope: { saveHandler: '&onSave' },
      link: link,
    };

    return directive;

    //////////////////////

    function link(scope) {
      scope.validationPending = false;

      scope.$on('event:validation.pending', function (event, pending) {
        scope.validationPending = pending;
      });

      scope.$on('event:fileUploadProgress', function (event, isUploading) {
        scope.fileUploadProgress = isUploading;
      });

      scope.save = function () {
        scope.saveHandler();
      };

      scope.cancel = function () {
        backUrlService.goBack();
      };
    }
  }
})();

(function () {
  angular.module('kmi.lms.components').directive('clickConfirmation', function ($uibModal) {
    var ModalInstanceCtrl = function ($scope, $uibModalInstance, $timeout, confirmationHandler) {
      $scope.processing = false;
      $scope.ok = function () {
        $timeout(function () {
          $scope.processing = true;
          if (confirmationHandler) {
            return confirmationHandler();
          }
        }).finally(function () {
          $scope.processing = false;
          $uibModalInstance.close();
        });
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    };

    return {
      restrict: 'A',
      scope: {
        clickConfirmation: '&',
      },
      link: function (scope, element, attrs) {
        element.bind('click', function () {
          var message = attrs.clickConfirmationMessage || 'Are you sure?',
            title = attrs.clickConfirmationTitle || 'Confirmation',
            buttonCancel = attrs.clickConfirmationButtonCancel || 'Cancel',
            buttonOk = attrs.clickConfirmationButtonOk || 'OK',
            ladda =
              'clickConfirmationLadda' in attrs && JSON.parse(attrs.clickConfirmationLadda) ? 'ladda="processing"' : '',
            allowed =
              ('clickConfirmationAllowed' in attrs && JSON.parse(attrs.clickConfirmationAllowed)) ||
              !('clickConfirmationAllowed' in attrs);

          if (!allowed) {
            scope.clickConfirmation();
            return;
          }

          var modalHtml =
            '<div class="modal-header"> <button type="button" class="close" aria-label="Close" ng-click="cancel()"> <span aria-hidden="true">×</span> </button> <h4 class="modal-title">' +
            title +
            '</h4> </div>';
          modalHtml += '<div class="modal-body">' + message + '</div>';
          modalHtml +=
            '<div class="modal-footer"><button class="btn btn-warning" ng-click="cancel()" title="' +
            buttonCancel +
            '">' +
            buttonCancel +
            '</button><button class="btn btn-success" ng-click="ok()" autofocus title="' +
            buttonOk +
            '" ' +
            ladda +
            '>' +
            buttonOk +
            '</button></div>';

          $uibModal.open({
            template: modalHtml,
            controller: ModalInstanceCtrl,
            resolve: {
              confirmationHandler: function () {
                return scope.clickConfirmation;
              },
            },
          });
        });
      },
    };
  });
})();

import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgInitDirective } from 'components/common/ng-init.directive';
import { ScoreComponent } from 'components/common/score.component';
import { UploadedImagePreviewComponent } from 'components/common/uploaded-image-preview.component';
import { FileInputComponent } from 'components/file-upload/file-input.component';
import { ImageFileInputComponent } from 'components/file-upload/image-file-input.component';
import { RequiredIndicatorComponent } from 'components/forms/required-indicator.component';
import { MarkdownInsertImageModalComponent } from 'components/markdown/modal/markdown-insert-image-modal.component';
import { MarkdownInsertLinkModalComponent } from 'components/markdown/modal/markdown-insert-link-modal.component';
import { MarkdownManagerService } from 'components/markdown/services/markdown-manager.service';
import { ReadTimeComponent } from 'components/read-time/read-time.component';
import { SelectDropdownComponent } from 'components/select-dropdown/select-dropdown.component';
import { TimerComponent } from 'components/timer/timer.component';
import { NgxMaskModule } from 'ngx-mask';
import { ExpandForMoreComponentDirective } from './common/expand-for-more-component.upgraded';
import { ThumbnailComponent } from './common/thumbnail.component';
import { LmsDatesModule } from './dates/dates.module';
import { EntityAuthorDirective } from './entities/entity-author.component.upgraded';
import { FileUploadService } from './file-upload/file-upload.service';
import { ImageUploadComponent } from './file-upload/image/image-upload-directive';
import { SingleFileUploadDirective } from './file-upload/single-file-upload.upgraded';
import {
  AutoFocusDirective,
  ClickConfirmationDirective,
  ConfirmationDialogComponent,
  KeepFocusDirective,
  ScrollToViewDirective,
  TrackLinkClickDirective,
  TrimValueDirective,
  UsersCountConfirmationCheckboxDirective,
} from './forms';
import { InfiniteScrollDirective } from './forms/infinite-scroll.directive';
import { ScrollToDirective } from './forms/scroll-to.directive';
import { ToggleButtonComponent } from './forms/toggle-button.component';
import {
  LmsPatternDirective,
  MaxValueValidatorDirective,
  MinValueValidatorDirective,
  NoWhitespaceDirective,
  UniqValueDirective,
  UrlValidatorDirective,
} from './forms/validators';
import { ValidJsonKeyDirective } from './forms/validators/valid-json-key.directive';
import { ApprovalStatusLabelComponent } from './labels/approval-status-label.component';
import { GridPaginationComponent } from 'components/grid/grid-pagination.component';
import { UploadedFilePreviewComponent } from 'components/common/uploaded-file-preview.component';
import { EmptyContainerComponent } from 'components/common/empty-container.component';
import { UIRouterModule } from '@uirouter/angular';
import { AttributesService } from 'components/attributes/services/attributes.service';
import { AttributeInfoComponent } from 'components/attributes/components/attribute-info.component';
import { EmptyMenuItemsDirective } from 'components/forms/empty-menu-items.directive';
import { AnchoredSectionComponent } from 'components/forms/anchored-section.component';
import { LaddaModule } from 'components/ladda/ladda.module';
import { LinksSelfTargetPipe, MarkdownToHtmlPipe, UrlVersionPipe } from 'components/pipes';
import { MarkdownEditorDirective, markdownServiceProvider } from 'components/markdown';
import { HtmlToPlainTextPipe } from 'components/pipes/html-to-plain-text.pipe';
import { MimicButtonDirective } from 'components/forms/mimic-button.directive';

@NgModule({
  imports: [
    LmsDatesModule,
    NgxMaskModule.forRoot(),
    LaddaModule.forRoot({
      style: 'expand-left',
      spinnerColor: '#FFF',
    }),
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgbDropdownModule,
    NgOptimizedImage,
    UIRouterModule,
  ],
  declarations: [
    MarkdownToHtmlPipe,
    AutoFocusDirective,
    KeepFocusDirective,
    ConfirmationDialogComponent,
    ClickConfirmationDirective,
    TrackLinkClickDirective,
    EntityAuthorDirective,
    MarkdownEditorDirective,
    UrlVersionPipe,
    SingleFileUploadDirective,
    UrlValidatorDirective,
    NoWhitespaceDirective,
    UsersCountConfirmationCheckboxDirective,
    LinksSelfTargetPipe,
    ScrollToViewDirective,
    ScrollToDirective,
    InfiniteScrollDirective,
    ExpandForMoreComponentDirective,
    ToggleButtonComponent,
    LmsPatternDirective,
    HtmlToPlainTextPipe,
    ValidJsonKeyDirective,
    TrimValueDirective,
    MinValueValidatorDirective,
    MaxValueValidatorDirective,
    ImageUploadComponent,
    ThumbnailComponent,
    SelectDropdownComponent,
    ScoreComponent,
    TimerComponent,
    MarkdownInsertLinkModalComponent,
    MarkdownInsertImageModalComponent,
    RequiredIndicatorComponent,
    FileInputComponent,
    ImageFileInputComponent,
    UploadedImagePreviewComponent,
    UploadedFilePreviewComponent,
    NgInitDirective,
    ReadTimeComponent,
    ApprovalStatusLabelComponent,
    GridPaginationComponent,
    EmptyContainerComponent,
    AttributeInfoComponent,
    EmptyMenuItemsDirective,
    UniqValueDirective,
    AnchoredSectionComponent,
    MimicButtonDirective,
  ],
  exports: [
    AutoFocusDirective,
    KeepFocusDirective,
    ConfirmationDialogComponent,
    ToggleButtonComponent,
    ClickConfirmationDirective,
    TrackLinkClickDirective,
    EntityAuthorDirective,
    MarkdownEditorDirective,
    MarkdownInsertLinkModalComponent,
    MarkdownInsertImageModalComponent,
    MarkdownToHtmlPipe,
    UrlVersionPipe,
    LaddaModule,
    SingleFileUploadDirective,
    UrlValidatorDirective,
    NoWhitespaceDirective,
    LmsPatternDirective,
    UsersCountConfirmationCheckboxDirective,
    LinksSelfTargetPipe,
    ExpandForMoreComponentDirective,
    NgbPaginationModule,
    LmsDatesModule,
    ScrollToViewDirective,
    ScrollToDirective,
    InfiniteScrollDirective,
    NgxMaskModule,
    HtmlToPlainTextPipe,
    TrimValueDirective,
    MinValueValidatorDirective,
    MaxValueValidatorDirective,
    ValidJsonKeyDirective,
    ImageUploadComponent,
    ThumbnailComponent,
    NgSelectModule,
    SelectDropdownComponent,
    ScoreComponent,
    TimerComponent,
    RequiredIndicatorComponent,
    ImageFileInputComponent,
    UploadedImagePreviewComponent,
    UploadedFilePreviewComponent,
    NgInitDirective,
    ReadTimeComponent,
    ApprovalStatusLabelComponent,
    GridPaginationComponent,
    FileInputComponent,
    EmptyContainerComponent,
    AttributeInfoComponent,
    EmptyMenuItemsDirective,
    UniqValueDirective,
    AnchoredSectionComponent,
    MimicButtonDirective,
  ],
  providers: [
    { provide: Window, useValue: window },
    { provide: Document, useValue: document },
    markdownServiceProvider,
    FileUploadService,
    UrlVersionPipe,
    MarkdownManagerService,
    AttributesService,
  ],
})
export class LmsComponentsModule {}

(function () {
  angular.module('kmi.lms.components').directive('focusFirstFormControl', [
    '$timeout',
    '$document',
    function ($timeout) {
      return {
        restrict: 'A',
        link: function ($scope, $element) {
          $timeout(function () {
            var controls = (<any>window).$($element).find('input, select, textarea'),
              firstFormControl;

            if (controls && controls.length) {
              firstFormControl = controls[0];
              firstFormControl.focus();

              if (document.activeElement !== firstFormControl) {
                firstFormControl.scrollIntoView();
              }
            }
          }, 100);
        },
      };
    },
  ]);
})();

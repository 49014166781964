(function () {
  angular.module('kmi.lms.components').directive('validateHashCode', function (sjcl) {
    return {
      require: 'ngModel',
      link: function (scope, element, attr, ngModelController) {
        ngModelController.$parsers.unshift(function (viewValue) {
          if (!viewValue || sjcl.codec.hex.fromBits(sjcl.hash.sha256.hash(viewValue)) !== attr.validateHashCode) {
            ngModelController.$setValidity('hash', false);
            return null;
          } else {
            ngModelController.$setValidity('hash', true);
            return viewValue;
          }
        });
      },
    };
  });
})();

(function () {
  angular.module('kmi.lms.components').component('readTimeComponent', {
    controller: ReadTimeComponentController,
    controllerAs: 'vm',
    template: require('ajs/components/texts/read-time/read-time.html').default,
    bindings: {
      text: '<',
    },
  });

  /* @ngInject */
  function ReadTimeComponentController($scope) {
    var vm = this;
    vm.$onInit = onInit;

    function onInit() {
      vm.readTime = calculate();

      $scope.$on('event:calculateReadingTime', function (event, text) {
        vm.text = text;
        vm.readTime = calculate();
      });
    }

    function calculate() {
      if (vm.text) {
        var wordsPerMinute = 200;
        var noOfWords = vm.text.split(/\s/g).length;
        var minutes = noOfWords / wordsPerMinute;

        return Math.ceil(minutes);
      }

      return 0;
    }
  }
})();

import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import moment from 'moment';

export class LmsValidators {
  static url(): ValidatorFn {
    const urlRegExp = new RegExp(
      '^(https?:\\/\\/(?:(?:\\w[\\w-]{0,61}\\.)+[A-Z]{2,63}|\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})(?::\\d+)?' +
        '|\\/(?:[\\w-]+\\.?)+)(?:\\/?|[\\/?]\\S+)$',
      'i',
    );

    return (control: AbstractControl): ValidationErrors | null => {
      const urlValid = urlRegExp.test(control.value);

      return urlValid || !control.value ? null : { url: { value: control.value } };
    };
  }

  public static pattern(nameRe: string | RegExp): ValidatorFn {
    const validator = Validators.pattern(nameRe);

    return (control: AbstractControl): ValidationErrors | null => {
      const error = validator(control);

      return error ? { pattern: true, message: 'Value is invalid.' } : null;
    };
  }

  public static required(control: AbstractControl): ValidationErrors | null {
    const required = Validators.required(control);

    return required ? { required: true, message: 'This field is required.' } : LmsValidators.whitespace(control);
  }

  public static minLength(value: number): ValidatorFn {
    const validator = Validators.minLength(value);

    return (control: AbstractControl): ValidationErrors | null => {
      const error = validator(control);

      return error ? { minLength: true, message: `Value must be at least ${value} characters long.` } : null;
    };
  }

  public static maxLength(value: number): ValidatorFn {
    const validator = Validators.maxLength(value);

    return (control: AbstractControl): ValidationErrors | null => {
      const error = validator(control);

      return error ? { maxLength: true, message: `Please use no more than ${value} characters.` } : null;
    };
  }

  public static min(value: number): ValidatorFn {
    const validator = Validators.min(value);

    return (control: AbstractControl): ValidationErrors | null => {
      const error = validator(control);

      return error ? { min: true, message: `Enter an integer greater than ${value}.` } : null;
    };
  }

  public static max(value: number): ValidatorFn {
    const validator = Validators.max(value);

    return (control: AbstractControl): ValidationErrors | null => {
      const error = validator(control);

      return error ? { max: true, message: `Enter an integer number less than or equal to ${value}.` } : null;
    };
  }

  public static range(min: number, max: number): ValidatorFn {
    const minValidator = Validators.min(min);
    const maxValidator = Validators.max(max);

    return (control: AbstractControl): ValidationErrors | null => {
      const error = minValidator(control) || maxValidator(control);

      return error ? { range: true, message: `Enter an integer value between ${min} and ${max}.` } : null;
    };
  }

  public static ngbDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dateValid = moment(control.value, 'MM/DD/YYYY').isValid();

      return dateValid || !control.value ? null : { ngbDate: { value: control.value } };
    };
  }

  public static whitespace(control: AbstractControl): ValidationErrors | null {
    const required = (control.value || '').trim().length === 0;

    return required ? { required: true, whitespace: true, message: 'This field is required.' } : null;
  }

  public static compose(validators: ValidatorFn[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      for (let i = 0; i < validators.length; i++) {
        const validator = validators[i];
        const error = validator(control);

        if (error) {
          return error;
        }
      }

      return null;
    };
  }

  public static validateEquality(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const control = formGroup.get(controlName);
      const matchingControl = formGroup.get(matchingControlName);

      if (!control || !matchingControl) {
        return null;
      }

      if (matchingControl.errors && !matchingControl.errors['equal']) {
        // return if another validator has already found an error on the matchingControl
        return null;
      }

      // Set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ equal: true });
      } else {
        matchingControl.setErrors(null);
      }

      return null;
    };
  }
}

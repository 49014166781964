import { Component, Inject, Optional } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

const REGEXP =
  /^(https?:\/\/(?:(?:\w[\w-]{0,61}\.)+[A-Z]{2,63}|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?|\/(?:[\w-]+\.?)+)(?:\/?|[/?]\S+)$/i;

@Component({
  standalone: false,
  selector: 'markdown-insert-image-modal-component',
  templateUrl: './markdown-insert-image-modal.component.html',
})
export class MarkdownInsertImageModalComponent {
  regexp = REGEXP;
  url = '';
  constructor(
    private activeModal: NgbActiveModal,
    @Inject('disableAttachments') @Optional() public disableAttachments: boolean,
  ) {}

  cancel(): void {
    this.activeModal.dismiss();
  }

  addUrl(): void {
    this.activeModal.close({
      url: this.url,
    });
  }

  uploadFile(): void {
    this.activeModal.close({
      upload: true,
    });
  }
}

(function () {
  angular.module('kmi.lms.components').component('expandForMoreComponent', {
    template: require('ajs/components/expand-for-more-component.upgradable.html').default,
    controller: ExpandForMoreComponentController,
    controllerAs: 'vm',
    bindings: {
      content: '=',
      trackingName: '=',
      parentId: '=',
      placeUnder: '=',
    },
  });

  /* @ngInject */
  function ExpandForMoreComponentController() {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {}
  }
})();

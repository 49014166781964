(function () {
  angular.module('kmi.lms.components').component('timeIndicator', {
    template: require('ajs/components/dates/time-indicator.html').default,
    controller: DateTimeController,
    controllerAs: 'vm',
    bindings: {
      date: '=',
      timeZone: '<?',
      hideTz: '<?',
    },
  });

  /* @ngInject */
  function DateTimeController(moment) {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {
      vm.timeZone = vm.timeZone || moment.tz.guess();
      vm.timeZoneAbbr = moment.tz(vm.date, vm.timeZone).zoneAbbr();
    }
  }
})();

(function () {
  angular.module('kmi.lms.components').directive('emptyMenuItems', emptyMenuItems);

  /* @ngInject*/
  function emptyMenuItems($timeout) {
    var directive = {
      restrict: 'A',
      link: link,
      scope: {
        emptyMenuMessage: '@',
        emptyMenuItems: '=',
      },
    };
    return directive;

    function link(scope, element) {
      scope.$watch(
        'emptyMenuItems',
        function () {
          $timeout(function () {
            var hiddenItemsCount = element.parent().find('li.ng-hide a').length;
            var allItemsCount = element.parent().find('a').length;

            if (hiddenItemsCount === allItemsCount) {
              if (element.find('span').length === 0) {
                element.append('<span>' + scope.emptyMenuMessage + '</span>');
              }
            } else {
              element.empty();
            }
          }, 100);
        },
        true,
      );
    }
  }
})();

(function () {
  angular.module('kmi.lms.components').directive('elmsFileUploadComponent', function () {
    return {
      restrict: 'EA',
      controller: ElmsFileUploadController,
    };
  });

  /* @ngInject */
  function ElmsFileUploadController(
    rootScopeService,
    $scope,
    $http,
    fileService,
    fileMaxSize,
    elms,
    serverErrorHandlerService,
    _,
  ) {
    /**
     * @description
     * File uploader component options
     */
    var uploadHandler = null;

    $scope.fileUploadOptions = {
      url: $scope.options.url,
      autoUpload: true,
      formData: $scope.options.formData,
      dropZone: $scope.dropZone,
      maxNumberOfFiles: 1,
      disableImageMetaDataLoad: true,
      disableImageLoad: true,
      maxFileSize: ($scope.options.fileMaxSize || fileMaxSize) * 1024 * 1024,
    };

    if ($scope.options.acceptFileTypes) {
      $scope.fileUploadOptions.acceptFileTypes = $scope.options.acceptFileTypes;
    }

    if ($scope.options.acceptFileTypesString) {
      $scope.acceptFileTypesString = $scope.options.acceptFileTypesString;
    }

    $scope.model.file = null;
    $scope.uploadedFile = null;
    $scope.isHTML5 = fileService.isHTML5;

    activate();

    function activate() {
      //file upload event handlers
      $scope.$on('fileuploadadd', function (e, data) {
        uploadHandler = data;

        $scope.fileUploadError = null;
        $scope.uploadedFile = data.files[0];
        rootScopeService.broadcast('event:fileUploadProgress', true);
        rootScopeService.broadcast('event:fileUploadProgressAdd', data);
      });

      $scope.$on('fileuploaddone', function (e, data) {
        $scope.model.file = $scope.uploadedFile;
        $scope.model.file.tmpName = _.trim(data.result, '"');
        rootScopeService.broadcast('event:fileUploadProgress', false);
        uploadHandler = null;
      });

      $scope.$on('fileuploadprocessfail', function () {
        $scope.fileUploadError = $scope.uploadedFile.error;
        rootScopeService.broadcast('event:fileUploadProgress', false);
        destroyFile();
      });

      $scope.$on('fileuploadfail', function (e, data) {
        if (!data.result && _.includes([408, 413], _.get(data, 'jqXHR.status', null))) {
          data.result = 'File is too large';
        } else {
          data.result = 'Internal Server Error';
        }
        $scope.fileUploadError = data.result;
        if (!data.result) {
          serverErrorHandlerService.handleForbiddenError(data.xhr());
        }
        rootScopeService.broadcast('event:fileUploadProgress', false);
        destroyFile();
      });

      $scope.$on('fileuploadprogressall', function (e, data) {
        $scope.uploadedFile.uploadProgress = Math.min(100, parseInt((100.0 * data.loaded) / data.total, 10));
      });

      $scope.$on('event:clearBlob', function () {
        destroyFile();
      });

      $scope.$on('$destroy', function () {
        if (uploadHandler) {
          uploadHandler.abort();
        }
      });
    }

    function destroyFile() {
      $scope.uploadedFile = null;
      $scope.queue = [];
      $scope.model.file = null;
      uploadHandler = null;
    }

    /**
     * @description
     * Deletes file on the server
     */
    $scope.deleteFile = function () {
      var fileName = $scope.model.file.tmpName;

      if (fileName) {
        destroyFile();

        $http({
          url: $scope.options.url,
          method: 'delete',
          params: { name: fileName },
        });
      }
    };

    $scope.formatFileSize = function (size) {
      return elms.formatBytes(size);
    };
  }
})();

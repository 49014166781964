<div [ngClass]="{ 'timepicker-row flex-direction-column-xs gap-10': display === 'inline' }">
  <div [ngClass]="{ 'd-flex': display === 'inline' }">
    <div class="input-group">
      <input
        type="text"
        [attr.aria-label]="'Date'"
        class="form-control"
        ngbDatepicker
        datepickerClass="uib-datepicker-popup"
        [id]="id"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [footerTemplate]="dpFooterTemplate"
        [minDate]="config.minDate"
        [maxDate]="config.maxDate"
        [ngClass]="color"
        #ngDate="ngbDatepicker" />

      <span class="input-group-btn">
        <button
          type="button"
          class="btn btn-default"
          title="Open Calendar"
          [disabled]="disabled"
          (click)="toggleCalendar()">
          <span class="fa fa-calendar-days"></span>
        </button>
      </span>

      <ng-template #dpFooterTemplate>
        <div class="ngb-dp-button-bar">
          <span class="btn-group">
            <button
              type="button"
              class="btn btn-sm btn-info uib-datepicker-current ng-binding"
              [disabled]="!isTodayAvailable()"
              (click)="setToday()">
              Today
            </button>
            <button type="button" class="btn btn-sm btn-danger uib-clear ng-binding" (click)="clear()">Clear</button>
          </span>
          <button type="button" class="btn btn-sm btn-success pull-right uib-close ng-binding" (click)="ngDate.close()">
            Done
          </button>
        </div>
      </ng-template>
    </div>
  </div>

  <div *ngIf="useTime" [ngClass]="{ 'd-flex': display === 'inline', 'input-group': display === 'block' }">
    <ngb-timepicker #ngTime></ngb-timepicker>
  </div>
</div>

(function () {
  angular.module('kmi.lms.components').directive('attributes', attributesDirective);

  function attributesDirective() {
    return {
      restrict: 'EA',
      scope: {
        attributes: '=',
        category: '=',
        submitted: '=',
        requiredByCategory: '=?',
        strictlyRequired: '=?',
        cacheEnabled: '=?',
        modelOptions: '=?ngModelOptions',
        skipErrorMessageOnPreview: '@?',
        mode: '@?',
        instance: '@?',
        instanceId: '=?',
      },
      template: require('ajs/components/attributes/attributes.html').default,
      controller: AttributesController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  /* @ngInject */
  function AttributesController(
    $scope,
    _,
    customAttributesConstants,
    notificationService,
    attributesService,
    $timeout,
  ) {
    let vm = this;

    let validationResults = {};

    vm.openedCalendars = {};
    vm.openCalendar = openCalendar;
    vm.changeLookupValue = changeLookupValue;
    vm.initAttribute = initAttribute;
    vm.changeCheckedValues = changeCheckedValues;
    vm.changePrimary = changePrimary;
    vm.useUiMask = useUiMask;
    vm.getUiMask = getUiMask;
    vm.getPattern = getPattern;
    vm.getCheckedValuesCount = getCheckedValuesCount;
    vm.$onInit = onInit;
    vm.getFormAttributeError = getFormAttributeError;
    vm.validateRadio = validateRadio;

    function getFormAttributeError(attribute) {
      if (
        $scope[vm.formName] &&
        $scope[vm.formName][attribute.formLabel] &&
        $scope[vm.formName][attribute.formLabel].validationError
      ) {
        return $scope[vm.formName][attribute.formLabel].validationError;
      }

      return 'Incorrect attribute value.';
    }

    function onInit() {
      if (vm.category) {
        vm.category.checkedValues = vm.category.checkedValues || 0;
      }
      vm.formName = attributesService.getFormName(vm.category);

      if (vm.category && vm.category.checkbox) {
        $scope.$watch('vm.category.checkedValues', function () {
          attributesService.validateMinCheckedValues(vm.category);
        });
        if (vm.category.primaryRequired) {
          $scope.$watch('vm.category.primaryId', changePrimary);
        }
      }
    }

    /**
     * @description
     * Opens a date picker
     * @param $event
     */
    function openCalendar($event, attribute) {
      $event.preventDefault();
      $event.stopPropagation();
      vm.openedCalendars[attribute.formLabel] = true;
    }

    /**
     * @description
     * Set correct lookup value
     * @param atribute
     */
    function changeLookupValue(attribute) {
      var item = _.find(attribute.lookupValues, function (item) {
        return item.id === attribute.valueId;
      });
      attribute.specify = item ? item.specify : false;
      attribute.value = null;
    }

    function getCheckedValuesCount() {
      return vm.attributes.reduce((acc, item) => {
        if (item.checked) {
          acc++;
        }
        return acc;
      }, 0);
    }

    /**
     * @description
     * Set up initial properties for attribute
     * @param atribute
     */
    function initAttribute(attribute) {
      var id = attribute.typeId || attribute.id;

      // code below must be before any usage of attribute.require
      if (vm.requiredByCategory && (!vm.category || !vm.category.required)) {
        attribute.required = false;
        vm.category.minCheckedValues = 0;
      }

      if (vm.cacheEnabled) {
        var cache = attributesService.getFromCache(id);
        if (cache) {
          angular.extend(attribute, cache);
        } else {
          attribute.value = attribute.valueId = null;
          attribute.checked = attribute.primary = false;
        }
      }

      if (vm.category && vm.category.checkbox) {
        attribute.checked = false;
        if (attribute.value || attribute.valueId) {
          attribute.checked = true;
          vm.category.checkedValues = vm.getCheckedValuesCount();
        }
        if (vm.category.primaryRequired && attribute.primary) {
          vm.category.primaryId = id;
        }
        attributesService.validateMinCheckedValues(vm.category);
      }

      // Note. All labels are proposed for view only. Don't operate labels in our business logic
      const randSuffix = Math.random().toString(20).slice(4, 10);
      attribute.formLabel = [customAttributesConstants.fieldPrefix, id, randSuffix].join('');
      attribute.specifyLabel = attribute.formLabel + 'SpecifyValue';
      attribute.checkboxLabel = attribute.formLabel + 'Checkbox';

      if (attribute.valueTypeId === 3) {
        vm.openedCalendars[attribute.formLabel] = false;
      }

      if (vm.cacheEnabled) {
        var index = _.findIndex(vm.attributes, { id: attribute.id });
        if (index === -1) {
          index = _.findIndex(vm.attributes, { typeId: attribute.typeId });
        }
        $scope.$watch(
          'vm.attributes[' + index + ']',
          function (newValue, oldValue) {
            if (!angular.equals(newValue, oldValue)) {
              attributesService.saveToCache(attribute);
            }
          },
          true,
        );
      }
    }

    function validateRadio(attribute) {
      function setValidity(valid, message?) {
        $scope[vm.formName][attribute.formLabel].validationError = message;
        $scope[vm.formName][attribute.formLabel].$setValidity('validateAttributeValue', valid);
        $scope[vm.formName][attribute.formLabel].$setDirty();
      }

      let modelValue = attribute.value;
      $scope[vm.formName][attribute.formLabel].validationError = '';
      $scope[vm.formName][attribute.formLabel].$setValidity('validateAttributeValue', undefined);

      if ($scope[vm.formName] && $scope[vm.formName][attribute.formLabel]) {
        let ping = function (sessionId, attempt) {
          attributesService.getValidationResult(sessionId).then((validationResult) => {
            let status = validationResult ? validationResult.status : null;

            if (status === 'valid') {
              validationResults[attribute.formLabel][modelValue] = {
                error: null,
                valid: true,
              };
              setValidity(true);
            } else if (status === 'invalid') {
              validationResults[attribute.formLabel][modelValue] = {
                error: validationResult.message,
                valid: false,
              };
              setValidity(false, validationResult.message);
            } else if (attempt >= 10) {
              setValidity(false, 'Attribute cannot be validated. Please try again.');
            } else {
              $timeout(function () {
                ping(sessionId, attempt + 1);
              }, 200);
            }
          });
        };

        if (!validationResults[attribute.formLabel]) {
          validationResults[attribute.formLabel] = {};
        }

        if (validationResults[attribute.formLabel][modelValue]) {
          $scope[vm.formName][attribute.formLabel].validationError =
            validationResults[attribute.formLabel][modelValue].error;
          $scope[vm.formName][attribute.formLabel].$setValidity(
            'validateAttributeValue',
            validationResults[attribute.formLabel][modelValue].valid,
          );
          $scope[vm.formName][attribute.formLabel].$setDirty();
        } else {
          attributesService.validateAttrValue(vm.instanceId, attribute.id, modelValue, vm.instance).then(
            function (sessionId) {
              if (sessionId) {
                ping(sessionId, 0);
              } else {
                setValidity(false, 'Attribute cannot be validated. Please try again.');
              }
            },
            function () {
              setValidity(false, 'Attribute cannot be validated. Please try again.');
            },
          );
        }
      }
    }

    /**
     * @description
     * Set correct checked value with min/max control
     * @param atribute
     */
    function changeCheckedValues(attribute) {
      var attrId = attribute.id || attribute.typeId;
      if (attribute.checked) {
        if (vm.category.primaryRequired && !vm.category.checkedValues) {
          vm.category.primaryId = attrId;
        }
        vm.category.checkedValues = vm.getCheckedValuesCount();
        if (attribute.valueTypeId === 1) {
          attribute.value = true;
        }
      } else {
        if (vm.category.primaryRequired && attrId === vm.category.primaryId) {
          vm.category.primaryId = null;
        }
        attribute.value = attribute.valueId = null;
        attribute.specify = false;
        vm.category.checkedValues = vm.getCheckedValuesCount();
      }
      if (vm.category.checkedValues === vm.category.maxCheckedValues && vm.category.maxCheckedValues !== 0) {
        notificationService.info('You selected a maximum amount of "' + vm.category.name + '"', 3e3);
      }
    }

    function changePrimary(newId, oldId) {
      var oldPrimary = getAttributeById(oldId);
      var newPrimary = getAttributeById(newId);
      if (oldPrimary) {
        oldPrimary.primary = false;
      }
      if (newPrimary) {
        newPrimary.primary = true;
      }
    }

    function getAttributeById(id) {
      return _.find(vm.attributes, { id: id }) || _.find(vm.attributes, { typeId: id });
    }

    function useUiMask(attribute) {
      return attribute.mask?.length > 0;
    }
    function getUiMask(attribute) {
      if (useUiMask(attribute)) {
        return attribute.mask;
      }
      return null;
    }
    function getPattern(attribute) {
      if (!useUiMask(attribute)) {
        return attribute.pattern;
      }
      return null;
    }
  }
})();

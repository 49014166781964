(function () {
  /**
   * @ngdoc filter
   * @name kmi.lms.components.filter:htmlToPlaintext
   * @description
   * Remove html tags. if exists parameter spaceDelimiter replate tags to spaces
   */
  angular.module('kmi.lms.components').filter('htmlToPlaintext', htmlToPlaintext);

  function htmlToPlaintext() {
    return function (text, spaceDelimiter) {
      if (angular.isUndefined(spaceDelimiter)) {
        return text ? String(text).replace(/<[^>]+>/gm, '') : '';
      } else {
        var result = text ? String(text).replace(/<[^>]+>/gm, ' ') : '';
        return String(result).replace(/ +/g, ' ');
      }
    };
  }
})();

(function () {
  angular.module('kmi.lms.components').directive('focusFirstInvalidInput', focusFirstInvalidInput);

  function focusFirstInvalidInput($timeout) {
    return {
      restrict: 'A',
      link: function focusFistInvalidFormControl(scope, element) {
        element.find('input[type=submit]').on('click', function () {
          var invalidInputs, tabName;
          invalidInputs = element.find(':input.ng-invalid');

          if (invalidInputs && invalidInputs.length) {
            tabName = angular.element(invalidInputs[0]).parents('[ng-form]').attr('name');
            if (tabName) {
              if (scope.hasOwnProperty('currentTab')) {
                scope.currentTab = tabName;
              }
              if (scope.hasOwnProperty('vm')) {
                if (scope.vm.hasOwnProperty('currentTab')) {
                  scope.vm.currentTab = tabName;
                }
              }
            }

            $timeout(function () {
              invalidInputs[0].scrollIntoView({
                behavior: 'smooth',
                inline: 'nearest',
                block: 'center',
              });
              window.scrollBy({
                left: 0,
                top: -100,
                behavior: 'smooth',
              });
              invalidInputs[0].focus();
            }, 100);
          }
        });
      },
    };
  }
})();

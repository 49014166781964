import { Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'required-field',
  templateUrl: './required-indicator.component.html',
})
export class RequiredIndicatorComponent {
  @Input() screenReaderText: string;
}

(function () {
  /**
   * @ngdoc filter
   * @name kmi.lms.components.filter:courseHoursFormatter
   * @description
   * Format course length in hours
   */
  angular.module('kmi.lms.components').filter('courseHoursFormatter', courseHoursFormatter);

  function courseHoursFormatter() {
    return function (hours) {
      hours = parseFloat(hours);
      //hours = hours.toPrecision(2);
      return isNaN(hours) ? '' : hours + 'h';
    };
  }
})();

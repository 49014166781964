<form ngForm #insertImageForm="ngForm" (ngSubmit)="addUrl()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">Insert Image</h4>
  </div>

  <div class="modal-body">
    <div *ngIf="!disableAttachments">
      <div class="mb-5">
        <strong>Upload from computer</strong>
      </div>
      <div>
        <button type="button" class="btn btn-default" (click)="uploadFile()">
          <i class="fa-solid fa-upload" aria-hidden="true"></i> Upload
        </button>
      </div>
    </div>
    <hr class="hr-or-text bg-white text-muted" data-content="OR" />
    <label for="Hyperlink">Hyperlink</label>
    <input type="url" [(ngModel)]="url" name="Hyperlink" id="Hyperlink" class="form-control" [lmsPattern]="regexp" />
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-warning" (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-success" [disabled]="insertImageForm.invalid">Save hyperlink</button>
  </div>
</form>

(function () {
  angular.module('kmi.lms.components').factory('sharedRequestService', SharedRequestService);

  /* @ngInject */
  function SharedRequestService($timeout, $q, uuid, _) {
    const requestIntentKey = 'requestIntent',
      inProcessKey = 'inProcess',
      responseStatusKey = 'responseStatus',
      dataReceivedKey = 'dataReceived';

    return {
      init: init,
    };

    function init(channelName) {
      return new Wrapper(channelName);
    }

    function Wrapper(channelName) {
      var inst = this;

      inst.id = uuid.v4();
      /*
         Allow to block multiple executions in parallel
         For example multiple browser tabs
         Note, if requests called in sequence one by one, then all of them will be executed
      */
      inst.wrap = function (callable) {
        init(callable);
        selectMaster();
        return inst.callablePromise.promise;
      };

      function init(callable) {
        try {
          inst.channel = new BroadcastChannel(channelName);
          inst.channel.addEventListener('message', onMessage);
        } catch {
          inst.channel = null;
          console.warn('cant create broadcast channel');
        }

        inst.handlers = {};
        inst.handlers[requestIntentKey] = onRequestIntent;
        inst.handlers[inProcessKey] = onRequestInProcess;
        inst.handlers[dataReceivedKey] = onDataReceived;
        inst.handlers[responseStatusKey] = onStatusUpdated;

        inst.callable = callable;
        inst.lastStatusChecked = null;
        inst.callablePromise = $q.defer();
        inst.created = getTime();
      }

      function destroy() {
        if (inst.channel) {
          inst.channel.removeEventListener('message', onMessage);
          inst.channel.close();
        }
        inst.callablePromise = null;
        inst.lastStatusChecked = null;
        if (inst.watchdog) {
          $timeout.cancel(inst.watchdog);
        }
        if (inst.aliveNotifier) {
          $timeout.cancel(inst.aliveNotifier);
        }
      }

      /*
        Notify all processes that we want to process request.
        If notification system is down, just process
       */
      function selectMaster() {
        if (broadcastMessage(requestIntentKey, { created: inst.created })) {
          inst.selectMasterTimeout = $timeout(requestImpl, 100);
        } else {
          requestImpl();
        }
      }

      /*
        Another process want to be a master. If he is younger,
        then notify about self as a master process
       */
      function onRequestIntent(payload) {
        if (payload.created >= inst.created) {
          broadcastMessage(inProcessKey, { created: inst.created });
        }
      }

      /*
        Has another process that want to process request too.
        If he is older, then he is a master. Stop request and wait for data
       */
      function onRequestInProcess(payload) {
        if (payload.created < inst.created) {
          $timeout.cancel(inst.selectMasterTimeout);
          watchdog();
        }
      }

      /*
        Run request implementation and notify other processes
        that we still here and alive
       */
      function requestImpl() {
        inst.processing = inst
          .callable()
          .then(function (data) {
            broadcastMessage(dataReceivedKey, data);
            onDataReceived(data);
          })
          .finally(function () {
            inst.processing = null;
          });
        notifyMasterAlive();
      }

      /*
        Data received callback
       */
      function onDataReceived(data) {
        inst.callablePromise.resolve(data);
        destroy();
      }

      /*
        Store time when master process was alive last time
       */
      function onStatusUpdated() {
        inst.lastStatusChecked = getTime();
      }

      function broadcastMessage(message, payload) {
        if (inst.channel) {
          inst.channel.postMessage({ message: message, payload: payload, from: inst.id });
          return true;
        }
        return false;
      }

      function onMessage(event) {
        var handler = _.get(inst.handlers, event.data.message, function () {
          console.warn(inst.id, 'Undefined message type', event.data);
        });
        handler(event.data.payload);
      }

      /*
        Wait until another process complete request and send result
       */
      function watchdog() {
        if (!inst.lastStatusChecked) {
          inst.lastStatusChecked = getTime();
        }

        if (inst.watchdog) {
          $timeout.cancel(inst.watchdog);
        }

        if (getTime() - inst.lastStatusChecked > 5000) {
          // If master is doesn't respond, restart process
          inst.lastStatusChecked = null;
          selectMaster();
          return;
        }

        inst.watchdog = $timeout(watchdog, 1000);
      }

      /*
        Notify all others process that master still here and processing request
       */
      function notifyMasterAlive() {
        broadcastMessage(responseStatusKey);
        inst.aliveNotifier = $timeout(notifyMasterAlive, 1000);
      }

      function getTime() {
        const d = new Date();
        return d.getTime();
      }
    }
  }
})();

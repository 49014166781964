import { Component, EventEmitter } from '@angular/core';

@Component({
  standalone: false,
  selector: 'confirmation-dialog',
  template: ` <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">×</span>
      </button>
      <h4 class="modal-title">{{ title }}</h4>
    </div>
    <div class="modal-body" [innerHtml]="message"></div>
    <div class="modal-footer">
      <button class="btn btn-warning" (click)="cancel()" [title]="buttonCancelText">{{ buttonCancelText }}</button>
      <button class="btn btn-success" (click)="ok()" [title]="buttonOkText" ngbAutofocus [ladda]="ladda">
        {{ buttonOkText }}
      </button>
    </div>`,
})
export class ConfirmationDialogComponent {
  title: string;
  message: string;
  buttonOkText = 'Ok';
  buttonCancelText = 'Cancel';
  confirmHandler = new EventEmitter<boolean>();
  ladda = false;

  cancel(): void {
    this.confirmHandler.emit(false);
  }

  ok(): void {
    this.confirmHandler.emit(true);
  }
}

(function () {
  angular.module('kmi.lms.components').directive('expandForMore', expandForMore);

  function expandForMore($window, $timeout, $http) {
    var directive = {
      restrict: 'EA',
      template: require('ajs/components/expand-for-more.html').default,
      scope: {
        //'max': '=maxHeight',
        content: '=expandForMore',
        trackingName: '<?',
        parentId: '<?',
        placeUnder: '@',
      },
      link: link,
    };
    return directive;

    function link(scope, element) {
      scope.max = 0;
      scope.overflow = false;
      scope.expanded = false;
      scope.toggleMore = toggleMore;
      scope.maxClampHeight = {
        'max-height': scope.expanded ? 'auto' : scope.max + 'px',
      };

      var w = angular.element($window),
        container = element[0].querySelector('p.expand-for-more-content');

      scope.isLoading = function () {
        return $http.pendingRequests.length > 0;
      };

      scope.$watch(
        scope.isLoading,
        $timeout(function () {
          if (container.querySelectorAll('img').length > 0) {
            container.querySelectorAll('img').forEach(function (entry) {
              entry.addEventListener('load', function () {
                w.bind('resize', updateOnResize);
                scope.$watch('content', init);
              });
            });
          } else {
            w.bind('resize', updateOnResize);
            scope.$watch('content', init);
          }
        }),
      );

      w.bind('resize', updateOnResize);
      scope.$watch('content', init);

      function init() {
        $timeout(function () {
          //get the max height from the .line-clamp container
          var lineClamp = element[0].querySelector('.line-clamp');
          if (lineClamp) {
            scope.max = element[0].querySelector('.line-clamp').offsetHeight;
            scope.overflow = container.offsetHeight > scope.max;
          }
        });
      }

      function updateOnResize() {
        $timeout(function () {
          scope.overflow = container.offsetHeight > scope.max;
        });
      }

      function correctScrollOffset() {
        function getTopOffset() {
          var elms = angular.element(scope.placeUnder);
          if (elms.length > 0) {
            return elms[0].getBoundingClientRect().height;
          }
          return 0;
        }

        function getTopBound() {
          return angular.element($window).height() / 4;
        }

        function shouldCorrectScrollOffset() {
          if (scope.expanded || !scope.parentId || !scope.trackingName) {
            return false;
          }
          var anchor = element[0].querySelector('#' + scope.trackingName + '_link');
          return anchor && anchor.getBoundingClientRect().top < getTopBound();
        }

        function scrollImpl() {
          var elms = angular.element(document.getElementById(scope.parentId));
          if (elms.length > 0) {
            elms[0].scrollIntoView();
            document.documentElement.scrollTop -= getTopOffset();
          }
        }

        /*
         Ugly hack. I should to wait one frame while the dom tree will be recalculated after content collapse.
         Otherwise getTopOffset could return old value
         */
        $window.requestAnimationFrame(function () {
          $timeout(function () {
            if (shouldCorrectScrollOffset()) {
              scrollImpl();
            }
          });
        });
      }

      function toggleMore() {
        scope.expanded = !scope.expanded;
        scope.maxClampHeight['max-height'] = scope.expanded ? 'auto' : scope.max + 'px';
        correctScrollOffset();
      }
    }
  }
})();

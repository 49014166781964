import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFileUploadOptions, IFileUploadProgressCallback } from 'components/file-upload/file-upload.model';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';
import { filter, last, map, mergeMap, Observable, of, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class FileUploadService {
  static readonly WATCH_HTTP_STATUSES = [400, 401, 403, 409, 422, 429];

  constructor(private http: HttpClient) {}

  upload(file: File, options: IFileUploadOptions, throwHttpErrors = false): Observable<string> {
    if (!this.validateFileSize(file, options)) {
      return throwError(() => new HttpErrorResponse({ error: 'File size exceeds limit' }));
    }

    const fd = new FormData();

    fd.append('file', file);

    if (options.formData) {
      Object.keys(options.formData).forEach((key) => {
        fd.append(key, options.formData[key]);
      });
    }

    const req = new HttpRequest('POST', options.endPoint, fd, {
      reportProgress: true,
      context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors),
    });

    return this.http.request(req).pipe(
      tap((event) => this.trackProgress(event, options.trackProgress)),
      mergeMap((event) => this.getEventMessage(event)),
      filter((message) => !!message),
      last(),
    );
  }

  generateImage(prompt: string, aiAuthToken: string, throwHttpErrors = false): Observable<Promise<File>> {
    const url = ['/ai-tools/images/suggestions/'].join('/');

    return this.http
      .post(
        url,
        {
          prompt: prompt,
        },
        {
          headers: new HttpHeaders({
            Authorization: `Bearer ${aiAuthToken}`,
          }),
          context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors),
        },
      )
      .pipe(
        map(async (data: any) => {
          return await this.dataUrlToFile(data.image, 'generated-image.jpg');
        }),
      );
  }

  private async dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();

    return new File([blob], fileName, { type: 'image/jpeg', lastModified: Date.now() });
  }

  private trackProgress(event: HttpEvent<any>, trackProgress: IFileUploadProgressCallback) {
    if (event.type === HttpEventType.UploadProgress && trackProgress) {
      trackProgress(event.total ? Math.round((100 * event.loaded) / event.total) : 0);
    }
  }

  private getEventMessage(event: HttpEvent<any>) {
    if (event.type === HttpEventType.Response) {
      return of(event.body);
    } else if (event.type === HttpEventType.ResponseHeader) {
      if (event.status !== 200) {
        return throwError(() => {
          return event.statusText;
        });
      }
    }

    return of(undefined);
  }

  private validateFileSize(file, options: IFileUploadOptions) {
    return file.size <= (options.maxFileSizeMb || 12) * 1024 * 1024;
  }
}

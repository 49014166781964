(function () {
  angular.module('kmi.lms.components').component('phone', {
    template: require('ajs/components/phone.html').default,
    controller: PhoneController,
    controllerAs: 'vm',
    bindings: {
      form: '=',
      formOptions: '=',
      user: '=',
      telephoneRequired: '=',
    },
  });

  /* @ngInject*/
  function PhoneController(_, globalConfig) {
    var vm = this;
    vm.settings = globalConfig.settings;

    vm.$onInit = activate;

    function activate() {
      if (_.get(vm.settings, 'usePhoneNumberRegex', false)) {
        vm.phoneNumberRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
        vm.phoneNumberMask = undefined;
      } else {
        vm.phoneNumberRegex = undefined;
        vm.phoneNumberMask = '(999) 999-9999';
      }
    }
  }
})();

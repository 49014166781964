import { Directive, EventEmitter, OnDestroy, SimpleChange } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { UpgradeComponent } from '@angular/upgrade/static';
import _ from 'lodash';
import { Unsubscribable } from 'rxjs';

@Directive()
export abstract class LmsValueAccessorUpgradeComponent<T>
  extends UpgradeComponent
  implements ControlValueAccessor, OnDestroy
{
  touched = false;
  isDisabled = false;

  onChange: (v: T) => void;
  onTouched: () => void;

  private previousValue: T;
  private currentValue: T;
  private isFirstChange = true;
  private changedSubscriber?: Unsubscribable;

  protected abstract transformValue(value: T): T;

  protected abstract get modelPropertyName(): string;
  protected abstract get valueChanged(): EventEmitter<T>;

  override ngOnDestroy(): void {
    this.changedSubscriber?.unsubscribe();
    delete this.changedSubscriber;
    super.ngOnDestroy();
  }

  registerOnChange(onChange: any) {
    this.changedSubscriber = this.valueChanged.subscribe((value) => this.onModelChange(value));
    this.onChange = onChange;
  }

  setDisabledState(isDisabled: boolean) {
    const simpleChange = new SimpleChange(this.isDisabled, isDisabled, false);

    this.isDisabled = isDisabled;
    this.ngOnChanges({ disabled: simpleChange });
  }

  // TODO implement onTouched
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  // TODO bug report: https://github.com/angular/angular/issues/14988
  writeValue(value: T) {
    // if (this.onChange && (!_.isEqual(value, this.currentValue) || this.isFirstChange)) {
    if (!_.isEqual(value, this.currentValue) || this.isFirstChange) {
      const simpleChange = new SimpleChange(this.previousValue, value, this.isFirstChange);

      this.previousValue = this.currentValue;
      this.currentValue = value;
      this.isFirstChange = false;
      this.ngOnChanges({ [this.modelPropertyName]: simpleChange });
    }
  }

  onModelChange(value: T) {
    if (this.onChange && !_.isEqual(value, this.currentValue)) {
      this.currentValue = _.cloneDeep(value);
      this.onChange(this.transformValue(value));
    }
  }
}

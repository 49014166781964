(function () {
  angular.module('kmi.lms.components').directive('submitOn', function () {
    return {
      link: function (scope, elm, attrs) {
        scope.$on(attrs.submitOn, function () {
          setTimeout(function () {
            elm.find('[type=submit]').trigger('click');
          });
        });
      },
    };
  });
})();

import { Injectable } from '@angular/core';
import { NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';

/**
 * A configuration service for the `NgbDatepicker` component.
 * https://ng-bootstrap.github.io/#/components/timepicker/examples#config
 */
@Injectable()
export class TimepickerConfig extends NgbTimepickerConfig {
  override meridian = true;
  override hourStep = 1;
  override minuteStep = 15;
  override secondStep = 15;
  override spinners = true;
  override seconds = false;
}

(function () {
  angular.module('kmi.lms.components').component('dateWithHiddenTime', {
    template: require('ajs/components/dates/date-with-hidden-time.html').default,
    controller: DateWithHiddenTimeController,
    controllerAs: 'vm',
    bindings: {
      date: '=',
      timeZone: '<?',
    },
  });

  function DateWithHiddenTimeController(moment) {
    let vm = this;

    vm.$onInit = onInit;

    function onInit() {
      vm.timeZone = vm.timeZone || moment.tz.guess();
      let dateTime = vm.date ? moment(vm.date).tz(vm.timeZone) : moment.tz(vm.timeZone);
      vm.longTimeZoneName = [vm.timeZone, ' (GMT ', dateTime.format('Z'), ')'].join('');
    }
  }
})();

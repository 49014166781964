(function () {
  angular.module('kmi.lms.components').directive('scrollOnEvent', scrollOnEvent);

  function scrollOnEvent($window, scrollService) {
    return {
      restrict: 'AC',
      link: function (scope, element) {
        var document = $window.document;

        scope.$on('event:scrollTo', scrollInto);
        scope.$on('event:scrollWithOffset', scrollWithOffset);

        function scrollWithOffset(event, idOrName, scrollOffset, offsetAll) {
          scrollService.scrollWithOffset(document, idOrName, scrollOffset, offsetAll);
        }

        function scrollInto(event, idOrName, block) {
          scrollService.scrollInto(document, element, idOrName, block);
        }
      },
    };
  }
})();

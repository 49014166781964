(function () {
  angular.module('kmi.lms.components').component('rotatorBar', {
    restrict: 'AE',
    replace: true,
    bindings: {
      count: '=',
      currentIndex: '=',
    },
    template: require('ajs/components/rotator-bar-template.html').default,
    controller: RotatorBarController,
    controllerAs: 'vm',
    bindToController: true,
  });

  /* @ngInject */
  function RotatorBarController() {
    var vm = this;

    vm.goToPreviousItem = goToPreviousItem;
    vm.goToNextItem = goToNextItem;

    //////////

    function goToNextItem() {
      var tempIndex = vm.currentIndex + 1;
      if (tempIndex < vm.count) {
        vm.currentIndex = tempIndex;
      } else if (vm.count > 2) {
        vm.currentIndex = 0;
      }
    }

    function goToPreviousItem() {
      var tempIndex = vm.currentIndex - 1;
      if (tempIndex >= 0) {
        vm.currentIndex = tempIndex;
      } else if (vm.count > 2) {
        vm.currentIndex = vm.count - 1;
      }
    }
  }
})();

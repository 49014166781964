(function () {
  //See formats at http://momentjs.com/

  angular.module('kmi.lms.components').directive('dateFormat', function (moment) {
    return {
      restriction: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
        scope.$watch(
          function () {
            return ngModel.$modelValue;
          },
          function () {
            var dateFormat = attrs.dateFormat;

            if (ngModel.$modelValue) {
              element.html(moment.utc(ngModel.$modelValue).format(dateFormat));
            }
          },
        );
      },
    };
  });
})();

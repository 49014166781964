(function () {
  angular.module('kmi.lms.components').directive('markdownEditor', markdownEditor);

  /* @ngInject */
  function markdownEditor(markdownService, markdownRender, markdownRenderHtml, globalConfig) {
    var directive = {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        html: '=',
        isDisabled: '<?',
        isPreview: '<?',
        disableAttachments: '<?',
        hideButtons: '<?',
      },
      link: link,
    };

    return directive;

    //////////////////////

    function link(scope, element, attrs, ngModelController) {
      var attachmentOptions = {
        enableAttachments: globalConfig.markdown.enableAttachments && !scope.disableAttachments,
      };

      if (angular.isDefined(scope.isDisabled)) {
        scope.$watch('isDisabled', function (value, oldValue) {
          if (value !== oldValue) {
            toggleDisableState(value);
          }
        });
      }

      scope.$on('event:formResponsive', function () {
        if (element.data('markdown')) {
          element.data('markdown').enableButtons('all');
          element.data('markdown').showEditor();
        }
      });

      if (scope.isPreview) {
        var cancelWatch = scope.$watch(
          function () {
            return ngModelController.$modelValue;
          },
          function () {
            if (element.data('markdown')) {
              element.data('markdown').showPreview();
              element.data('markdown').enableButtons('cmdPreview');
            }
            cancelWatch();
          },
        );
      }

      element.markdown({
        savable: false,
        attachmentOptions: attachmentOptions,
        iconlibrary: 'fa',
        parser: scope.html ? markdownRenderHtml : markdownRender,
        hiddenButtons: buildHiddenButtons(),
        fullscreen: { enable: false },
        footer: markdownService.getFooterContent,
        additionalButtons: [
          [
            {
              name: 'customLinksGroup',
              data: markdownService
                .getCustomLinksGroupButtons(attachmentOptions)
                .concat(markdownService.getSuperscriptGroupButtons()),
            },
          ],
        ],
        onChange: onChange,
        onShow: function (e) {
          if (!attachmentOptions.enableAttachments) {
            e.$editor.find('.md-footer').hide();
          }
        },
      });

      ////////////////////////

      function buildHiddenButtons() {
        const list = scope.html
          ? [
              'cmdUrl',
              'cmdImage',
              'cmdCode',
              'cmdBold',
              'cmdItalic',
              'attachmentButton',
              'cmdHeading',
              'cmdList',
              'cmdListO',
              'cmdQuote',
              'urlLinkButton',
              'imageButton',
            ]
          : ['cmdUrl', 'cmdImage', 'cmdCode'];

        if (angular.isArray(scope.hideButtons)) {
          list.push(...scope.hideButtons);
        }

        return list;
      }

      function onChange(e) {
        ngModelController.$setViewValue(e.getContent());
      }

      function toggleDisableState(value) {
        if (value === true) {
          element.data('markdown').disableButtons('all');
        }

        if (value === false) {
          element.data('markdown').enableButtons('all');
        }
      }
    }
  }
})();

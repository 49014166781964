(function () {
  angular.module('kmi.lms.components').directive('scrollTo', function ($window, $timeout, scrollService) {
    return {
      restrict: 'AC',
      compile: function () {
        var document = $window.document;

        return function (scope, element, attr) {
          element.bind('click', function () {
            $timeout(function () {
              if (attr.scrollOffset) {
                scrollService.scrollWithOffset(document, attr.scrollTo, attr.scrollOffset, attr.offsetAll);
              } else {
                scrollService.scrollWithOffset(document, attr.scrollTo, null, attr.offsetAll);
              }
            }, 10);
          });
        };
      },
    };
  });
})();

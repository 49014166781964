(function () {
  angular.module('kmi.lms.components').directive('emailRequired', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, elm, attrs, ctrl) {
        function validateEmail(receiveEmails, email) {
          if (!receiveEmails || (email && email.length)) {
            ctrl.$setValidity('emailRequired', true);
          } else {
            ctrl.$setValidity('emailRequired', false);
          }

          return receiveEmails;
        }

        scope.$watch(attrs.emailRequired, function (otherModelValue) {
          validateEmail(ctrl.$viewValue, otherModelValue);
        });

        ctrl.$parsers.unshift(function (viewValue) {
          return validateEmail(viewValue, scope.$eval(attrs.emailRequired));
        });

        ctrl.$formatters.unshift(function (modelValue) {
          return validateEmail(modelValue, scope.$eval(attrs.emailRequired));
        });
      },
    };
  });
})();

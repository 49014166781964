(function () {
  angular
    .module('kmi.lms.components')
    .constant('markdownFileMaxSize', 20) //In Mb
    .factory('markdownService', markdownService);

  function markdownService(
    rootScopeService,
    $filter,
    markdownFileMaxSize,
    globalConfig,
    notificationService,
    serverErrorHandlerService,
    _,
    markdownManagerServiceUpgraded,
  ) {
    var service = {
      isEnabled: !!globalConfig.markdown.enabled,
      toHtml: toHtml,
      insertLink: insertLink,
      insertImage: insertImage,
      addAttachment: addAttachment,
      getFooterContent: getFooterContent,
      urlEncodeImage: urlEncodeImage,
      getCustomLinksGroupButtons: getCustomLinksGroupButtons,
      getSuperscriptGroupButtons: getSuperscriptGroupButtons,
    };
    return service;

    ///////////////////

    function catchSelection(e) {
      var chunk,
        cursor,
        selected = e.getSelection();

      if (selected.length === 0) {
        // Give extra word
        chunk = e.__localize('enter description here');
      } else {
        chunk = selected.text;
      }

      return {
        chunk: chunk,
        cursor: cursor,
        selected: selected,
      };
    }

    function insertLink(e) {
      var selection = catchSelection(e);

      markdownManagerServiceUpgraded.showInsertLinkModal().then((link) => {
        insertLinkCallback(e, selection, link);
      }, angular.noop);
    }

    function insertImage(e, disableAttachements = false) {
      var selection = catchSelection(e);

      markdownManagerServiceUpgraded.showInsertImageModal(disableAttachements).then(function (result) {
        if (result.url) {
          insertImageCallback(e, selection, result.url);
        }
        if (result.upload) {
          openFileSelection(e, true, function addAttachmentCallback(fileInfo) {
            if (fileInfo) {
              insertImageCallback(e, selection, fileInfo.path, fileInfo.friendlyName);
            }
          });
        }
      }, angular.noop);
    }

    function insertImageLink(e) {
      insertImage(e, true);
    }

    function addAttachment(e) {
      var selection = catchSelection(e);

      openFileSelection(e, false, function addAttachmentCallback(fileInfo) {
        if (fileInfo) {
          insertLinkCallback(e, selection, fileInfo.path, fileInfo.friendlyName);
        }
      });
    }

    function setSuperscript(context) {
      var selection = catchSelection(context);

      context.replaceSelection('^' + selection.chunk + '^');
      context.change(context);

      selection.cursor = selection.selected.start + 1;

      // Set the cursor
      context.setSelection(selection.cursor, selection.cursor + selection.chunk.length);
    }

    function setSubscript(context) {
      var selection = catchSelection(context);

      context.replaceSelection('~' + selection.chunk + '~');
      context.change(context);

      selection.cursor = selection.selected.start + 1;

      // Set the cursor
      context.setSelection(selection.cursor, selection.cursor + selection.chunk.length);
    }

    function insertLinkCallback(context, selection, link, title) {
      if (link) {
        var sanitizedLink = angular.element('<div>' + link + '</div>').text();

        // transform selection and set the cursor into chunked text
        context.replaceSelection('[' + (title || selection.chunk) + '](' + sanitizedLink + ')');
        context.change(context);

        selection.cursor = selection.selected.start + 1;

        // Set the cursor
        context.setSelection(selection.cursor, selection.cursor + (title || selection.chunk).length);
      }
    }

    function insertImageCallback(context, selection, link, title) {
      if (link) {
        var sanitizedLink = angular.element('<div>' + link + '</div>').text();

        // transform selection and set the cursor into chunked text
        context.replaceSelection(
          '![' +
            (title || selection.chunk) +
            '](' +
            service.urlEncodeImage(sanitizedLink) +
            ' "' +
            context.__localize('enter image title here') +
            '")',
        );
        context.change(context);

        selection.cursor = selection.selected.start + 2;

        // Set the next tab
        context.setNextTab(context.__localize('enter image title here'));

        // Set the cursor
        context.setSelection(selection.cursor, selection.cursor + (title || selection.chunk).length);
      }
    }

    function urlEncodeImage(link) {
      return link.split(' ').reduce(function (x, y) {
        return x + '%20' + y;
      });
    }

    function toHtml(content) {
      if (service.isEnabled) {
        return $filter('markdownToHtml')(content || '');
      }

      return content;
    }

    function openFileSelection(mdEditor, images, callback) {
      var html = ['<input type="file" ', images ? 'accept="image/*"' : '', ' name="file" style="display:none" />'].join(
          '',
        ),
        editor = mdEditor.$editor,
        fileInput;
      editor.find('input[type=file]').remove();

      fileInput = angular.element(html);
      editor.append(fileInput);

      fileInput
        .fileupload({
          url: '/a/media/file/',
          type: 'POST',
          autoUpload: true,
          maxNumberOfFiles: 1,
          disableImageMetaDataLoad: true,
          disableImageLoad: true,
          maxFileSize: markdownFileMaxSize * 1024 * 1024,
          dropZone: null,
        })
        .bind('fileuploaddone', function (e, data) {
          callback(data.result);
          hideProgress();
        })
        .bind('fileuploadadd', showProgress)
        .bind('fileuploadprocessfail', function (e, data) {
          hideProgress();

          rootScopeService.apply(function () {
            notificationService.error(data.files[0].error, 5e3);
          });
        })
        .bind('fileuploadfail', function (e, data) {
          hideProgress();

          rootScopeService.apply(function () {
            if (angular.isUndefined(data.result) && _.get(data, 'jqXHR.status', null) === 413) {
              data.result = 'File is too large';
            }
            if (data.result) {
              notificationService.error(data.result, 5e3);
            } else {
              serverErrorHandlerService.handleForbiddenError(data.xhr()).catch(function (error) {
                if (error) {
                  notificationService.error('The remote server is not available at this time. Try again later.', 3e3);
                }
              });
            }
          });
        });

      // simulate a click on the input to open the file selector.
      fileInput.trigger('click');

      function showProgress() {
        editor.find('.md-footer').show();
        editor.find('.upload-progress').show();
      }

      function hideProgress() {
        editor.find('.upload-progress').hide();

        if (!mdEditor.$options.attachmentOptions.enableAttachments) {
          editor.find('.md-footer').hide();
        }
      }
    }

    function getFooterContent() {
      if (this.attachmentOptions.enableAttachments !== false) {
        return '<span><button type="button" class="btn btn-link" title="Attach a file" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-attachmentButton"><span class="fa-regular fa-file-image sm-margin-right position-relative"></span>Attach a file</button></span><span class="upload-progress" style="display:none"><span class="fa fa-spinner fa-spin sm-margin-right position-relative"></span> Uploading a file...</span>';
      } else {
        return '<span class="upload-progress" style="display:none"><span class="fa fa-spinner fa-spin sm-margin-right position-relative"></span>Uploading a file...</span>';
      }
    }

    function getCustomLinksGroupButtons(attachmentOptions) {
      var baseButtons = [
        {
          name: 'urlLinkButton',
          title: 'URL/Link',
          hotkey: 'Ctrl+L',
          icon: {
            glyph: 'fa-solid fa-link',
            fa: 'fa fa-link',
            'fa-3': 'icon-link',
          },
          callback: insertLink,
        },
        {
          name: 'imageButton',
          title: 'Image',
          hotkey: 'Ctrl+G',
          icon: {
            glyph: 'fa-solid fa-image',
            fa: 'fa fa-image',
            'fa-3': 'icon-picture',
          },
          callback: attachmentOptions.enableAttachments !== false ? insertImage : insertImageLink,
        },
      ];

      // enableAttachments can be undefined. Let's enable them in that case
      if (attachmentOptions.enableAttachments !== false) {
        baseButtons.push({
          name: 'attachmentButton',
          title: 'Attachment',
          hotkey: 'Ctrl+A',
          icon: {
            glyph: 'fa-solid fa-paperclip',
            fa: 'fa fa-paperclip',
            'fa-3': 'icon-picture',
          },
          callback: addAttachment,
        });
      }

      return baseButtons;
    }

    function getSuperscriptGroupButtons() {
      return globalConfig.environment === 'testing'
        ? [
            {
              name: 'superscriptButton',
              title: 'Superscript',
              hotkey: 'Ctrl+S',
              icon: {
                glyph: 'fa-solid fa-superscript',
                fa: 'fa fa-superscript',
                'fa-3': 'icon-picture',
              },
              callback: setSuperscript,
            },
            {
              name: 'subscriptButton',
              title: 'Subscript',
              hotkey: 'Ctrl+S',
              icon: {
                glyph: 'fa-solid fa-subscript',
                fa: 'fa fa-subscript',
                'fa-3': 'icon-picture',
              },
              callback: setSubscript,
            },
          ]
        : [];
    }
  }
})();

(function () {
  /**
   * @ngdoc directive
   * @name kmi.lms.components.directive:reportModelCompleteness
   *
   * @description
   * Emit event at form field completeness change
   */

  angular.module('kmi.lms.components').directive('reportModelCompleteness', reportModelCompleteness);

  function reportModelCompleteness() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: link,
    };
  }

  function link(scope, element, attrs, ctrl) {
    scope.$watch(
      attrs.ngModel,
      function (newValue) {
        var inputState = {},
          formState = {},
          filledIn = true;

        if (
          (!newValue || newValue.length === 0) &&
          newValue !== false &&
          newValue !== 0 &&
          !scope.$eval(attrs.ngDisabled)
        ) {
          filledIn = false;
        }

        inputState[attrs.reportModelCompleteness] = filledIn;

        if (ctrl.$$parentForm && ctrl.$$parentForm.$name) {
          formState[ctrl.$$parentForm.$name] = inputState;
        }

        scope.$emit('event:modelCompleteness:changed', {
          input: inputState,
          form: formState,
        });
      },
      true,
    );
  }
})();

import { FactoryProvider } from '@angular/core';

export abstract class MarkdownService {
  abstract toHtml(content: string): string;
}

export function markdownServiceFactory(injector: angular.auto.IInjectorService): MarkdownService {
  return injector.get<MarkdownService>('markdownService');
}

export const markdownServiceProvider: FactoryProvider = {
  provide: MarkdownService,
  useFactory: markdownServiceFactory,
  deps: ['$injector'],
};

import { Pipe, PipeTransform } from '@angular/core';
import { UrlVersionService } from 'core/services';

@Pipe({ name: 'urlVersion', standalone: false })
export class UrlVersionPipe implements PipeTransform {
  constructor(private urlVersionService: UrlVersionService) {}

  transform(url: string): string {
    const timestamp = this.urlVersionService.get();

    if (timestamp !== null) {
      url = [url, url.indexOf('?') === -1 ? '?' : '&', '_=', timestamp].join('');
    }

    return url;
  }
}

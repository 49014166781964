import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

const REGEXP =
  /^(https?:\/\/(?:(?:\w[\w-]{0,61}\.)+[A-Z]{2,63}|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?|\/(?:[\w-]+\.?)+)(?:\/?|[/?]\S+)$/i;

@Component({
  standalone: false,
  selector: 'markdown-insert-link-modal-component',
  templateUrl: './markdown-insert-link-modal.component.html',
})
export class MarkdownInsertLinkModalComponent {
  url = '';
  regex = REGEXP;

  constructor(private activeModal: NgbActiveModal) {}

  cancel(): void {
    this.activeModal.dismiss();
  }

  submit(): void {
    this.activeModal.close(this.url);
  }
}

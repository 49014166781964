(function () {
  /**
   * @ngdoc directive
   * @name kmi.lms.components.directive:handleArrayChange
   *
   * @description
   * Set dirty when array changed
   */

  angular.module('kmi.lms.components').directive('handleArrayChange', handleArrayChange);

  function handleArrayChange(_) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: link,
    };

    function link(scope, element, attrs, ctrl) {
      scope.$watch(
        attrs.ngModel,
        function (newValue, oldValue) {
          if (newValue && oldValue && !angular.equals(_.sortBy(newValue), _.sortBy(oldValue))) {
            ctrl.$setDirty();
            ctrl.$validate(); // don`t delete validation, because without it the 'requiredIfModelSaved' will be work incorrectly for User Profile Contacts and Groups
          }
        },
        true,
      );
    }
  }
})();

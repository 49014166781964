(function () {
  angular.module('kmi.lms.components').directive('validateAttributeValue', validateAttributeValue);

  /* @ngInject */
  function validateAttributeValue($q, attributesService, $timeout) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, elem, attr, ctrl) {
        let savedModelValue = scope.$eval(attr.ngModel),
          instance = attr.instance,
          instanceId = attr.instanceId,
          validationResults = {};

        if (attr['uibDatepickerPopup'] && savedModelValue && !(savedModelValue instanceof Date)) {
          savedModelValue = new Date(savedModelValue);
        }

        ctrl.$asyncValidators.validateAttributeValue = function (modelValue) {
          if (ctrl.$isEmpty(modelValue) || modelValue === savedModelValue) {
            // consider empty model valid
            return $q.resolve();
          }
          if (attr['uibDatepickerPopup']) {
            if (modelValue instanceof Date && savedModelValue instanceof Date) {
              if (modelValue.getDate() === savedModelValue.getDate()) {
                return $q.resolve();
              }
            }
          }

          let deferred = $q.defer(),
            typeId = scope.$eval(attr.attrTypeId);

          let ping = function (sessionId, attempt) {
            attributesService.getValidationResult(sessionId).then((validationResult) => {
              let status = validationResult ? validationResult.status : null;
              if (status === 'valid') {
                ctrl.$setDirty();
                deferred.resolve();
              } else if (status === 'invalid') {
                ctrl.validationError = validationResult.message;
                ctrl.$setDirty();
                deferred.reject();
              } else if (attempt >= 9) {
                validationResults[modelValue] = null;
                ctrl.validationError = 'Attribute cannot be validated. Please try again.';
                ctrl.$setDirty();
                deferred.reject();
              } else {
                $timeout(function () {
                  ping(sessionId, attempt + 1);
                }, 1e3);
              }
            });
          };

          if (validationResults[modelValue]) {
            return validationResults[modelValue].promise;
          } else {
            validationResults[modelValue] = deferred;

            attributesService.validateAttrValue(instanceId, typeId, modelValue, instance).then(
              function (sessionId) {
                if (sessionId) {
                  ping(sessionId, 0);
                } else {
                  validationResults[modelValue] = null;
                  ctrl.validationError = 'Attribute cannot be validated. Please try again.';
                  ctrl.$setDirty();
                  deferred.reject();
                }
              },
              function () {
                validationResults[modelValue] = null;
                ctrl.validationError = 'Attribute cannot be validated. Please try again.';
                ctrl.$setDirty();
                deferred.reject();
              },
            );
          }

          return deferred.promise;
        };
      },
    };
  }
})();

import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { IEntity } from './entity.model';

@Directive({
  standalone: false,
  selector: 'entity-author',
})
export class EntityAuthorDirective extends UpgradeComponent {
  static readonly selector = 'entityAuthor';

  @Input() entity!: IEntity;
  @Input() mode?: string;
  @Input() viewMode?: string;

  constructor(elementRef: ElementRef, injector: Injector) {
    super(EntityAuthorDirective.selector, elementRef, injector);
  }
}

import { Injectable, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownInsertImageModalComponent } from 'components/markdown/modal/markdown-insert-image-modal.component';
import { MarkdownInsertLinkModalComponent } from 'components/markdown/modal/markdown-insert-link-modal.component';

@Injectable()
export class MarkdownManagerService {
  constructor(
    private ngbModal: NgbModal,
    private injector: Injector,
  ) {}

  public showInsertLinkModal(): Promise<string> {
    return this.ngbModal.open(MarkdownInsertLinkModalComponent, { injector: this.injector }).result;
  }

  public showInsertImageModal(disableAttachments: boolean) {
    return this.ngbModal.open(MarkdownInsertImageModalComponent, {
      injector: Injector.create({
        parent: this.injector,
        providers: [{ provide: 'disableAttachments', useValue: disableAttachments }],
      }),
      size: 'lg',
    }).result;
  }
}

(function () {
  angular.module('kmi.lms.components').directive('uniqueLogin', uniqueLogin);

  /* @ngInject */
  function uniqueLogin($q) {
    var canceler;

    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, elem, attr, ctrl) {
        ctrl.$asyncValidators.uniqueLogin = function (newLogin) {
          var deferred = $q.defer(),
            user = scope.$eval(attr.user);

          if (!newLogin || !user) {
            deferred.resolve();
          } else if (user.loginName === newLogin) {
            deferred.resolve();
          } else {
            if (canceler) {
              canceler.resolve({ canceled: true });
            }
            canceler = $q.defer();

            user.checkLogin(newLogin, { timeout: canceler.promise }).then(function setLoginValidity(unique) {
              if (unique) {
                deferred.resolve();
              } else {
                deferred.reject();
              }
            });
          }

          return deferred.promise;
        };
      },
    };
  }
})();

(function () {
  angular.module('kmi.lms.components').directive('validRange', function () {
    return {
      require: 'ngModel',
      link: function (scope, element, attr, ngModelController) {
        var minValue, maxValue;
        minValue = attr.min ? scope.$eval(attr.min) : null;
        maxValue = attr.max ? scope.$eval(attr.max) : null;

        ngModelController.$parsers.unshift(function (viewValue) {
          var retValue = viewValue;
          ngModelController.$setValidity('min_border', true);
          ngModelController.$setValidity('max_border', true);

          if (isNaN(parseFloat(viewValue))) {
            return null;
          }

          if (minValue !== null && minValue > parseFloat(viewValue)) {
            retValue = null;
            ngModelController.$setValidity('min_border', false);
          }

          if (maxValue !== null && maxValue < parseFloat(viewValue)) {
            retValue = null;
            ngModelController.$setValidity('max_border', false);
          }
          return retValue;
        });
      },
    };
  });
})();

(function () {
  angular.module('kmi.lms.components').component('requiredField', {
    template: require('ajs/components/forms/required-form-field-indicator.component.html').default,
    controller: RequiredFieldController,
    controllerAs: 'vm',
    bindings: {
      screenReaderText: '<?',
    },
  });

  function RequiredFieldController() {
    const vm = this;
    vm.$onInit = onInit;

    function onInit() {}
  }
})();

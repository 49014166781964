(function () {
  angular.module('kmi.lms.components').directive('npiNumber', function () {
    var NPI_NUMBER_RE = /^\d{10}$/i;
    var STARTS_WITH_ZERO_RE = /^[^0](.*)$/i;
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function (scope, elm, attrs, ctrl) {
        ctrl.$validators.numbersOnly = function (modelValue) {
          return ctrl.$isEmpty(modelValue) || NPI_NUMBER_RE.test(modelValue);
        };
        ctrl.$validators.startWithZero = function (modelValue) {
          return ctrl.$isEmpty(modelValue) || STARTS_WITH_ZERO_RE.test(modelValue);
        };
      },
    };
  });
})();

import { DOCUMENT } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'linksSelfTarget', standalone: false })
export class LinksSelfTargetPipe implements PipeTransform {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private sanitizer: DomSanitizer,
  ) {}

  transform(value: string): string {
    if (!value) {
      return value;
    }

    const element = this.document.createElement('div');

    element.innerHTML = value;
    const firstElement = element.firstElementChild;

    if (firstElement) {
      const aElements = firstElement.getElementsByTagName('a');

      if (aElements) {
        for (let index = 0; index < aElements.length; index++) {
          const aElement = aElements.item(0);

          if (aElement) {
            aElement.target = '';
          }
        }
      }

      return this.sanitizer.bypassSecurityTrustHtml(firstElement.outerHTML) as string;
    }

    return value;
  }
}

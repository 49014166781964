(function () {
  angular.module('kmi.lms.components').component('timeZoneIndicator', {
    template: require('ajs/components/dates/time-zone-indicator.html').default,
    controller: TimeZoneIndicatorController,
    controllerAs: 'vm',
    bindings: {
      timeZone: '=',
      date: '=',
    },
  });

  /* @ngInject */
  function TimeZoneIndicatorController($scope, $window, moment) {
    var vm = this;
    vm.$onInit = onInit;

    function onInit() {
      $scope.$watch('vm.timeZone', updateTimeZone);
      $scope.$watch('vm.date', updateTimeZone);
    }

    function updateTimeZone() {
      var dateTime = vm.date ? moment(vm.date).tz(vm.timeZone) : moment.tz(vm.timeZone);
      vm.shortTimeZoneName = dateTime.format('z');
      vm.longTimeZoneName = [vm.timeZone, ' (GMT ', dateTime.format('Z'), ')'].join('');
    }
  }
})();

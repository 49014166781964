(function () {
  /**
   * @ngdoc filter
   * @name kmi.lms.components.filter:titlecase
   * @description
   * Titlecase filter
   */
  angular.module('kmi.lms.components').filter('titlecase', titlecaseFilter);

  function titlecaseFilter() {
    return function (input) {
      return angular.isString(input) && input.length > 0
        ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase()
        : input;
    };
  }
})();

(function () {
  angular.module('kmi.lms.components').factory('cachedRequestService', CachedRequestService);

  /* @ngInject */
  function CachedRequestService($q, _) {
    return {
      init: init,
    };

    function init(cacheKey) {
      var inst = {
        cacheKey: cacheKey,
      };

      return {
        wrap: wrap,
      };

      function getCachedKey() {
        return inst.cacheKey;
      }

      function getCachedData(ttl) {
        var cachedData = sessionStorage.getItem(getCachedKey());
        if (cachedData) {
          cachedData = JSON.parse(cachedData);
          if (_.get(cachedData, 'created', 0) > getTime() - (ttl || 0)) {
            return cachedData.data;
          }
        }
        return null;
      }

      function storeDataToCache(data) {
        sessionStorage.setItem(
          getCachedKey(),
          JSON.stringify({
            data: data,
            created: getTime(),
          }),
        );
      }

      /*
        Made request if data not in cache
      */
      function wrap(callable, ttl) {
        var defer = $q.defer();

        var data = getCachedData(ttl);
        if (data) {
          defer.resolve(data);
        } else {
          callable().then(function (data) {
            storeDataToCache(data);
            defer.resolve(data);
          });
        }

        return defer.promise;
      }

      function getTime() {
        const d = new Date();
        return d.getTime();
      }
    }
  }
})();
